<template>
	<div id="app">
		404 Page Not Found
	</div>
</template>

<script>
export default {
	name: 'PageNotFound'
}
</script>

<style lang="less" scoped>
#app {
	padding: 50px 0 0 50px;
	font-size: 36px;
}
</style>